import Vue from 'vue';
import App from './App.vue';
import router from './router';
import EnvPlugin from './plugin/EnvPlugin';
import SharedUI from '@prd/shared-ui';
import { LoginService } from '@prd/shared-core/dist/services';
import '@progress/kendo-ui/js/kendo.core.min.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.nl-NL.js';
import './components/index';
import { loadMessages, load } from '@progress/kendo-vue-intl';
import VCalendar from 'v-calendar';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import moment from 'moment/min/moment-with-locales.min.js';
moment.locale('nl');

import SideActionsPlugin from './plugin/sideActions/sideActions';

// Helpers
import { EventBus } from './events/EventBus';
import { ErrorHelper } from './helpers/errorHelper';
import { LoginHelper } from './helpers/loginHelper';
import { PeriodFilterHelper } from './helpers/periodFilterHelper';
import { SiteHelper } from './helpers/siteHelper';
import NavigationHelper from './helpers/navigationHelper';
import DictionaryHelper from './helpers/dictionaryHelper';
import DateHelper from './helpers/dateHelper';
import CacheHelper from './helpers/cacheHelper';
import PlaceSearchHelper from './helpers/placeSearchHelper';
import { RoleHelper } from './helpers/roleHelper';

// Services
import { BankHolidayService } from './services/bankHolidayService';
import { FacilityService } from './services/facilityService';
import { MediaService } from './services/mediaService';
import { BookerService } from './services/bookerService';
import { PropertyTypeService } from './services/propertyTypeService';
import { SiteService } from './services/siteService';
import { FeatureService } from './services/featureService';
import { CalendarService } from './services/calendarService';
import { PricesService } from './services/pricesService';
import { PriceRuleService } from './services/priceRuleService';
import { BookingService } from './services/bookingService';
import { SitePrecenseService } from './services/sitePrecenseService';
import { InvoiceService } from './services/InvoiceService';
import { MailService } from './services/mailService';
import { FinanceAccountService } from './services/financeAccountService';
import UserService from './services/userService';
import { HomeOwnerService } from './services/homeOwnerService';
import { DiscountService } from './services/discountService';
import { SiteInvoiceDataService } from './services/siteInvoiceDataService';
import { TenantService } from './services/tenantService';
import { PlaceService } from './services/placeService';
import { MeterReadingService } from './services/meterReadingsService';
import { AffiliatePlacesService } from './services/affiliatePlacesService';
import { ProductService } from './services/productService';
import { SearchService } from './services/searchService';
import { StatsService } from './services/statsService';
import UserRoleService from './services/userRoleService';
import InviteService from './services/inviteService';
import store from '@/store/index';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import nlNumbers from 'cldr-numbers-full/main/nl/numbers.json';
import nlLocalCurrency from 'cldr-numbers-full/main/nl/currencies.json';
import nlCaGregorian from 'cldr-dates-full/main/nl/ca-gregorian.json';
import nlDateFields from 'cldr-dates-full/main/nl/dateFields.json';

load(likelySubtags, currencyData, weekData, nlNumbers, nlLocalCurrency, nlCaGregorian, nlDateFields);

const nlMessages = {
    datepicker: {
        toggleCalendar: 'Alternar calendario',
    },
    calendar: {
        today: 'Vandaag',
    },
    dateinput: {
        increment: 'Incrementar valor',
        decrement: 'Disminuir valor',
    },
    numerictextbox: {
        increment: 'Incrementar valor',
        decrement: 'Disminuir valor',
    },
    grid: {
        groupPanelEmpty: 'Arrastre el t�tulo de una columna y su�ltelo aqu� para agrupar por ese criterio',
        noRecords: 'Geen data beschikbaar',
        pagerFirstPage: 'Ir a la primera p�gina',
        pagerPreviousPage: 'Ir a la p�gina anterior',
        pagerNextPage: 'Ir a la p�gina siguiente',
        pagerLastPage: 'Ir a la �ltima p�gina',
        pagerPage: 'P�gina',
        pagerOf: 'de',
        pagerItems: '�tems',
        pagerInfo: '{0} - {1} de {2} �tems',
        pagerItemsPerPage: '�tems por p�gina',
        filterEqOperator: 'Es igual a',
        filterNotEqOperator: 'No es igual a',
        filterIsNullOperator: 'Es nulo',
        filterIsNotNullOperator: 'No es nulo',
        filterIsEmptyOperator: 'Est� vac�o',
        filterIsNotEmptyOperator: 'No est� vac�o',
        filterStartsWithOperator: 'Comienza con',
        filterContainsOperator: 'Contiene',
        filterNotContainsOperator: 'No contiene',
        filterEndsWithOperator: 'Termina en',
        filterGteOperator: 'Es mayor o igual que',
        filterGtOperator: 'Es mayor que',
        filterLteOperator: 'Es menor o igual que',
        filterLtOperator: 'Es menor o igual que',
        filterIsTrue: 'S�',
        filterIsFalse: 'No',
        filterBooleanAll: '(Todas)',
        filterAfterOrEqualOperator: 'Es posterior o igual a',
        filterAfterOperator: 'Es posterior',
        filterBeforeOperator: 'Es anterior',
        filterBeforeOrEqualOperator: 'Es anterior o igual a',
        filterFilterButton: 'Filtrar',
        filterClearButton: 'Verwijder filters',
        filterAndLogic: 'En',
        filterOrLogic: 'Of',
    },
};
loadMessages(nlMessages, 'nl-NL');
kendo.culture('nl-NL');

const aDictionaryHelper = new DictionaryHelper();
aDictionaryHelper.setActiveLanguage(kendo.culture().name);

Vue.use(Notifications);
Vue.use(VCalendar, {
    firstDayOfWeek: 2,
    locale: 'nl',
    masks: { L: 'DD-MM-YYYY' },
    datePicker: { popover: { visibility: 'click', placement: 'top', direction: 'top', align: 'left' } },
});
Vue.use(Vuelidate);
Vue.use(SideActionsPlugin);
Vue.use(SharedUI);
Vue.use(EnvPlugin);

export const loginService = new LoginService({
    endpoint: Vue.$env().IdentityEndpoint,
    localStorageKey: 'recreapi-auth',
});

export const $router = router;
export const eventBus = new EventBus();
export const siteService = new SiteService();
export const sitesHelper = new SiteHelper();
export const mediaService = new MediaService();
export const bookerService = new BookerService();
export const facilityService = new FacilityService();
export const propertyTypeService = new PropertyTypeService();
export const bankHolidayService = new BankHolidayService();
export const featureService = new FeatureService();
export const calendarService = new CalendarService();
export const pricesService = new PricesService();
export const priceRuleService = new PriceRuleService();
export const bookingService = new BookingService();
export const productService = new ProductService();
export const searchService = new SearchService();
export const sitePrecenseService = new SitePrecenseService();
export const invoiceService = new InvoiceService();
export const mailService = new MailService();
export const financeAccountService = new FinanceAccountService();
export const userService = new UserService();
export const userRoleService = new UserRoleService();
export const inviteService = new InviteService();
export const homeOwnerService = new HomeOwnerService();
export const discountService = new DiscountService();
export const siteInvoiceDataService = new SiteInvoiceDataService();
export const placeService = new PlaceService();
export const tenantService = new TenantService();
export const meterReadingService = new MeterReadingService();
export const affiliatePlacesService = new AffiliatePlacesService();
export const statsService = new StatsService();

export const loginHelper = new LoginHelper();
export const errorHelper = new ErrorHelper(router);
export const periodFilterHelper = new PeriodFilterHelper();
export const navigationHelper = new NavigationHelper();
export const dictionaryHelper = aDictionaryHelper;
export const dateHelper = new DateHelper();
export const cacheHelper = new CacheHelper();
export const placeSearchHelper = new PlaceSearchHelper();
export const roleHelper = new RoleHelper();

Vue.config.productionTip = false;

Vue.filter('friendlyDate', (value) => {
    if (!value) {
        return '';
    }
    return moment(value).format('dddd D MMMM');
});

Vue.filter('formatDate', (value) => {
    if (!value) {
        return '';
    }
    return moment(value).format('DD-MM-YYYY');
});

Vue.filter('formatPrice', (value) => {
    return kendo.toString(value, 'c');
});

new Vue({
    router,
    data: {
        environment: (window as any).environment,
        isLoading: true,
        roleHelper,
    },
    store,
    render: (h) => h(App),
}).$mount('#app');
