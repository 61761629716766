import Component from 'vue-class-component';
import Booking from '../../models/Booking';
import { Prop } from 'vue-property-decorator';
import Booker from '../../models/Booker';
import Product from '../../models/Product';
import PageRender from '../../models/PageRender';
import to from 'await-to-js';
import { productService, bookingService, searchService, loginService, siteService, loginHelper } from '@/main';
import moment from 'moment/min/moment.min.js';
import { SearchPlaceTypeParamsInterface } from '@/models/Interfaces';
import Place from '@/models/Place';
import { BModal } from 'bootstrap-vue';
import { sitesModule } from '@/store/modules/site';
import travelGroupVue from './travel-group.vue';

@Component({
    components: {
        TravelGroup: travelGroupVue,
    },
})
export default class BookingOverview extends PageRender {
    @Prop({ default: new Booking() }) public booking: Booking;
    @Prop({ default: () => [] }) public travelTypes: any[];
    @Prop({ default: () => [] }) public facets: any[];
    @Prop({ default: () => [] }) public bookedPlaceTypes: any[];
    @Prop({ default: 0 }) public price: number;
    public isLoading: boolean = true;
    public isCalculatingPrice: boolean = false;
    public licensePlateEdit: boolean = false;
    public placeIdEdit: boolean = false;
    public memoEdit: boolean = false;
    public editTravelGroup = false;
    public newLicensePlate: string = '';
    public availablePlaces: any[] = [];
    public places: Place[];

    public $refs!: {
        savePlacesWarningModal: BModal;
    };

    private get site() {
        return sitesModule.activeSite;
    }

    public async mounted() {
        await sitesModule.fetchSitesIfNeeded();
        this.places = (await siteService.getPlaces(this.site.siteId)).data;
        this.isLoading = false;
    }

    public getMainBooker(): Booker {
        return new Booker(this.booking.mainBooker);
    }

    public getTravelType(travelTypeId: number): string {
        const type = this.travelTypes.find((travelType: any) => {
            return travelType.type === travelTypeId;
        });

        return type ? type.description : '';
    }

    public getTotalNights() {
        return this.booking.priceObject.totalDays;
    }

    public getDayPrice(placeTypeId) {
        const dayPrice = this.booking.priceObject.dayPrices.find((x) => x.placeTypeId === placeTypeId);
        return typeof dayPrice !== 'undefined' && dayPrice ? dayPrice.calculatedDayPrice : 0;
    }

    public getTotalDayPrice(placeTypeId) {
        return this.booking.priceObject.totalDays * this.getDayPrice(placeTypeId);
    }

    public getTotalPrice(): number {
        if (this.booking.bookingId) {
            return this.booking.price;
        }

        let total = 0;

        this.bookedPlaceTypes.forEach((placeObject: any) => {
            total += placeObject.placeType.price.calculatedPrice;
        });

        return total;
    }

    public getPlaceName(place) {
        const placeId = place.placeId ? place.placeId : place;
        const bookedPlace = this.places.find((x) => x.placeId === placeId);
        if (!bookedPlace) {
            return 'Geboekte accommodatie niet beschikbaar, is deze verwijderd?';
        }

        return `${bookedPlace.name} (Plek: ${bookedPlace.lotNumber})`;
    }

    public editLicensePlates() {
        this.licensePlateEdit = true;
    }

    public cancleSavePlaces() {
        this.$refs.savePlacesWarningModal.hide();
    }

    public async confirmSavePlaces() {
        this.showPending('Accommodaties updaten..');

        const places = this.booking.places.map((x) => {
            if (typeof x.placeId === 'object') {
                return x.placeId.placeId;
            }

            return x.placeId;
        });

        const [err, reponse] = await to(bookingService.updatePlaces(places, this.booking.bookingId));
        if (err) {
            this.clearAndShowError('Misukt om de accommodaties up te daten.', err);
            return;
        }

        this.clearAndShowSuccess('Accommodaties geupdatet');

        this.$refs.savePlacesWarningModal.hide();
        this.placeIdEdit = false;
        this.$router.go(0);
    }

    public savePlaces() {
        this.$refs.savePlacesWarningModal.show();
    }

    public async editPlaceIds() {
        this.availablePlaces = [];
        const [err, searchResult] = await to(
            searchService.accommodation(({
                site: this.site.siteId.toString(),
                siteOwner: loginHelper.getSiteOwner().toString(),
                fromCapacity: 1,
                toCapacity: 99,
                fromDate: moment(this.booking.arrivalDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                toDate: moment(this.booking.departureDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            } as unknown) as SearchPlaceTypeParamsInterface),
        );

        if (err) {
            return this.showFailedResponse('Mislukt om beschikbare accommodaties op te halen.', err);
        }

        if (searchResult && searchResult.data && searchResult.data.accommodationTypes && searchResult.data.accommodationTypes.length > 0) {
            for (const acco of searchResult.data.accommodationTypes) {
                this.availablePlaces.push(
                    ...acco.placeIds.map((x) => {
                        return { placeId: x };
                    }),
                );
            }
        }

        this.placeIdEdit = true;
    }

    public async updateBooking() {
        this.showPending('Boeking opslaan..');
        const [err, response] = await to(bookingService.updateBooking(this.booking));
        if (err || !response) {
            return this.clearAndShowError('Mislukt om boeking te updaten.', err);
        }

        this.memoEdit = false;
        this.licensePlateEdit = false;
        this.editTravelGroup = false;
        return this.clearAndShowSuccess('Boeking opgeslagen');
    }

    public addLicensePlate() {
        this.booking.licensePlates.push(this.newLicensePlate.toUpperCase());
        this.newLicensePlate = '';
    }

    public removeLicensePlate(index) {
        this.booking.licensePlates.splice(index, 1);
    }

    private async getProducts(): Promise<Product[]> {
        const [err, response] = await to(productService.getProducts(this.site.siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om producten op te halen.', err);
            return [] as Product[];
        }

        return response.data;
    }
}
