import Component from 'vue-class-component';
import BasePriceRuleComponent from './base';
import to from 'await-to-js';
import { siteService } from '@/main';
import { sitesModule } from '@/store/modules/site';

@Component({
    name: 'Accommodation',
})
export default class Accommodation extends BasePriceRuleComponent {
    public isLoading: boolean = false;
    public places = [];

    public get site() {
        return sitesModule.activeSite;
    }

    public async mounted() {
        this.isLoading = true;
        const [err, response] = await to(siteService.getPlaces(this.site.siteId));
        this.isLoading = false;

        if (err) {
            this.showError('Kan geen accommodaties ophalen.');
            return;
        }

        this.places = response.data;
    }
}
