import Vue from 'vue';
import { BaseService } from './baseService';
import Booking from '../models/Booking';
import moment from 'moment/min/moment.min.js';
import { to } from 'await-to-js';

export class BookingService extends BaseService {
    public params: any[] = [];

    private endpoint = `${Vue.$env().BookingServiceEndpoint}bookings`;
    private calculateEndpoint = `${Vue.$env().BookingServiceEndpoint}calculate-booking`;
    private travellerEndpoint = `${Vue.$env().BookingServiceEndpoint}travelgroups`;
    private planBoardEndpoint = `${Vue.$env().BookingServiceEndpoint}planboard`;

    public getBookings(
        siteId: number,
        bookingState?: number,
        start?: any,
        end?: any,
        checkedIn?: boolean,
        arrivalDate?: any,
        departureDate?: any,
        mainBookerId?: number,
    ) {
        this.params = [['siteId', siteId]];
        let queryString = '';

        if (bookingState) {
            this.params.push(['bookingState', bookingState]);
            queryString += '&bookingState=:?bookingState';
        }

        if (checkedIn) {
            this.params.push(['checkedIn', checkedIn]);
            queryString += '&checkedIn=:?checkedIn';
        }

        if (start) {
            this.params.push(['fromDate', moment(start).format('YYYY-MM-DD')]);
            queryString += '&fromDate=:?fromDate';
        }

        if (end) {
            this.params.push([
                'toDate',
                moment(end)
                    .add('month', 1)
                    .format('YYYY-MM-DD'),
            ]);
            queryString += '&toDate=:?toDate';
        }

        if (arrivalDate) {
            this.params.push(['arrivalDate', moment(arrivalDate).format('YYYY-MM-DD')]);
            queryString += '&arrivalDate=:?arrivalDate';
        }

        if (departureDate) {
            this.params.push(['departureDate', moment(departureDate).format('YYYY-MM-DD')]);
            queryString += '&departureDate=:?departureDate';
        }

        if (mainBookerId) {
            this.params.push(['mainBookerId', mainBookerId]);
            queryString += '&mainBookerId=:?mainBookerId';
        }

        return this.get<Booking[]>(`${this.endpoint}?siteId=:siteId${queryString}`, this.params);
    }

    public getBooking(booking: string, siteId: number) {
        return this.get<Booking>(`${this.endpoint}/:booking?siteId=:siteId`, [
            ['booking', booking],
            ['siteId', siteId],
        ]);
    }

    public async getArrivals(siteId: number, arrivalDate?: string) {
        const [err, reponse] = await to(this.get(`${this.endpoint}/arrivals?siteId=${siteId}&arrivalDate=${arrivalDate}`));
        if (err) {
            return [];
        }

        return reponse.data;
    }

    public async getDepartures(siteId: number, departureDate?: string) {
        const [err, reponse] = await to(this.get(`${this.endpoint}/departures?siteId=${siteId}&departureDate=${departureDate}`));
        if (err) {
            return [];
        }

        return reponse.data;
    }

    public addBooking(booking: Booking, siteId: number) {
        return this.post<Booking>(`${this.endpoint}?siteId=:siteId`, booking, [['siteId', siteId]]);
    }

    public updateBooking(booking: Booking) {
        return this.put<Booking>(`${this.endpoint}/:bookingId`, booking, [['bookingId', booking.bookingId]]);
    }

    public getTravelGroupTypes(): any[] {
        return [
            { type: 3, description: 'Volwassene (18-65)', travellerType: 'Adult' },
            { type: 2, description: 'Kind (3-18)', travellerType: 'ChildThreeYearsAndUp' },
            { type: 1, description: 'Baby (0-2)', travellerType: 'ZeroUntilTwoYears' },
            { type: 4, description: '65+', travellerType: 'Senior' },
            { type: 5, description: 'Huisdier', travellerType: 'Pet' },
        ];
        // return this.get<any[]>(`${this.travellerEndpoint}/travellertypes`);
    }

    public getBookingStates() {
        return this.get<any[]>(`${this.endpoint}/states`);
    }

    public updatePlaces(places: number[], bookingId: number) {
        const model = {
            placeIds: [...places],
        };
        return this.post(`${this.endpoint}/:bookingId/update-places`, model, [['bookingId', bookingId]]);
    }

    public cancelBooking(bookingId: number, siteId: number, cancellationPeriod: string) {
        return this.put<Booking>(`${this.endpoint}/:bookingId/cancel?siteId=:siteId&cancellationPeriod=:cancellationPeriod`, null, [
            ['siteId', siteId],
            ['bookingId', bookingId],
            ['cancellationPeriod', cancellationPeriod],
        ]);
    }

    public updateBookingState(bookingId: number, siteId: number, bookingState: string) {
        return this.put<Booking>(`${this.endpoint}/:bookingId/change-state?siteId=:siteId&nextState=:nextState`, null, [
            ['siteId', siteId],
            ['bookingId', bookingId],
            ['nextState', bookingState],
        ]);
    }

    public acceptBooking(bookingId: number, siteId: number) {
        return this.updateBookingState(bookingId, siteId, 'Confirmed');
    }

    public declineBooking(bookingId: number, siteId: number) {
        return this.updateBookingState(bookingId, siteId, 'Rejected');
    }

    public paymentRecieved(bookingId: number, siteId: number) {
        return this.updateBookingState(bookingId, siteId, 'Paid');
    }

    public checkOut(bookingId: number, siteId: number) {
        return this.updateBookingState(bookingId, siteId, 'CheckedOut');
    }

    public checkIn(bookingId: number, siteId: number) {
        return this.updateBookingState(bookingId, siteId, 'CheckedIn');
    }

    public getPlanBoard(siteId: number, start?: string, end?: string) {
        if (start && end) {
            return this.get(`${this.planBoardEndpoint}/?site_id=:siteId&start_date=:start&end_date=:end`, [
                ['siteId', siteId],
                ['start', start],
                ['end', end],
            ]);
        }

        return this.get(`${this.planBoardEndpoint}/?site_id=:siteId`, [['siteId', siteId]]);
    }

    public calculateBookingPrice(booking: Booking, siteId: number) {
        return this.post(`${this.calculateEndpoint}/price?siteId=${siteId}`, booking);
    }

    public getOptionalProductsAsync(booking: Booking, siteId: number): Promise<any> {
        return this.post(`${this.calculateEndpoint}/optional-products?siteId=${siteId}`, booking);
    }

    public getBookingByInvoicePaymentLinkId(paymentLinkId: string) {
        return this.get<Booking>(`${this.endpoint}/ByPayment/:paymentLinkId`, [['paymentLinkId', paymentLinkId]]);
    }
}
