import { BaseService } from './baseService';
import Vue from 'vue';

export class PriceRuleService extends BaseService {
    private endpoint = `${Vue.$env().BookingServiceEndpoint}`;
    public getPriceRule(priceRuleId: string) {
        return this.get(`${this.endpoint}price-rule/:priceRuleId`, [['priceRuleId', priceRuleId]]);
    }

    public getPriceRules(siteId?: number) {
        if (siteId) {
            return this.get(`${this.endpoint}price-rules?site_id=:siteId`, [['siteId', siteId]]);
        }

        return this.get(`${this.endpoint}price-rules`);
    }

    public getPriceRuleTypes() {
        return [
            'DeltaDays',
            'BeginDay',
            'EndDay',
            'ContainsDay',
            'DateRange',
            'SingleDate',
            'DaysBeforeBooking',
            'AccommodationType',
            'Accommodation',
            'PreferredPlace',
            'ReturningVisitor',
            'NumberOfTravellers',
            'ContainsTravellerType',
            'IsHomeOwner',
            'DiscountCode',
        ];

        return this.get(`${this.endpoint}/priceruletypes`, []);
    }

    public storePriceRule(priceRule: any): Promise<any> {
        return this.post(`${this.endpoint}price-rules`, priceRule);
    }

    public deletePriceRule(priceRuleId: number, siteId?: number) {
        if (siteId) {
            return this.delete(`${this.endpoint}price-rules/:priceRuleId?siteId=:siteId`, [
                ['priceRuleId', priceRuleId],
                ['siteId', siteId],
            ]);
        }

        return this.delete(`${this.endpoint}price-rules/:priceRuleId`, [['priceRuleId', priceRuleId]]);
    }
}
