import Site from '../models/Site';
import Vue from 'vue';
import { BaseService } from './baseService';
import Place from '../models/Place';
import PlaceType from '../models/PlaceType';
import Facility from '../models/Facility';
import Feature from '../models/Feature';
import MapSettings from '@/models/Map/MapSettings';

export class SiteService extends BaseService {
    private placeEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'places';
    private placeTypesEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'place-types';
    private sitesEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'sites';
    private homeOwnerPlaceEndpoints = `${Vue.$env().SiteInformationServiceEndpoint}/home-owner-places`;

    public async createOrUpdateSite(model: Site) {
        return this.post(this.sitesEndpoint, model);
    }

    public createOrUpdatePlace(site, model: Place) {
        return this.post(`${this.sitesEndpoint}/${site}/places`, model);
    }

    public getSites() {
        return this.get<Site[]>(`${this.sitesEndpoint}`);
    }

    public getSite(siteId: number) {
        return this.get<Site>(`${this.sitesEndpoint}/:site`, [['site', siteId]]);
    }

    public getPlaces(site?: number) {
        return this.get<Place[]>(`${this.placeEndpoint}?site_id=:site`, [['site', site]]);
    }

    public getPlace(place: string) {
        return this.get<Place>(`${this.placeEndpoint}/:place`, [['place', place]]);
    }

    public getPlaceTypes(siteId: number) {
        return this.get<PlaceType[]>(`${this.placeTypesEndpoint}?site_id=:siteId`, [['siteId', siteId]]);
    }

    public getPlaceType(site: string, type: string) {
        return this.get<PlaceType>(`${this.sitesEndpoint}/:site/place-types/:type`, [
            ['site', site],
            ['type', type],
        ]);
    }

    public createOrUpdatePlaceType(site: string, placeType: PlaceType) {
        return this.post(`${this.sitesEndpoint}/${site}/place-types`, placeType);
    }

    public deletePlaceType(placeTypeId: number) {
        return this.delete(`${this.placeTypesEndpoint}/:placeTypeId`, [['placeTypeId', placeTypeId]]);
    }

    public deletePlace(placeId: number) {
        return this.delete(`${this.placeEndpoint}/:placeId`, [['placeId', placeId]]);
    }

    public getSiteIdsWithFacility(facility: Facility) {
        return this.get<number[]>(`${this.sitesEndpoint}/used?facilityId=:facilityId`, [['facilityId', facility.facilityId]]);
    }

    public getPlaceIdsWithFeature(site: string, feature: Feature) {
        return this.get<number[]>(`${this.sitesEndpoint}/:site/places/used?featureId=:featureId`, [
            ['site', site],
            ['featureId', feature.featureId],
        ]);
    }

    public updateHomeOwnerPlace(place: Place) {
        const model = {
            homeOwnerId: place.homeOwnerId,
            desiredROI: place.desiredROI,
            commissionRate: place.commissionRate,
            propertyValue: place.propertyValue,
            rentCosts: place.rentCosts,
            serviceCosts: place.serviceCosts,
            rentalState: place.rentalState,
            plotType: place.plotType,
        };

        return this.put(`${this.homeOwnerPlaceEndpoints}/${place.placeId}`, model);
    }

    public async getMapSettings(siteId: number): Promise<MapSettings> {
        const response = await this.get(`${this.sitesEndpoint}/${siteId}/map-settings`);
        return response ? response.data as MapSettings : null;
    }

    public async saveMapSettings(siteId: number, settings: MapSettings) {
        return !!await this.post(`${this.sitesEndpoint}/${siteId}/map-settings`, {
            boundingBoxTopLat: +settings.boundingBoxTopLat,
            boundingBoxTopLong: +settings.boundingBoxTopLong,
            boundingBoxBottomLat: +settings.boundingBoxBottomLat,
            boundingBoxBottomLong: +settings.boundingBoxBottomLong,
            remoteImageId: settings.remoteImageId,
            url: settings.url,
        } as MapSettings);
    }
}
